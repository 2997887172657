import React from "react"
export default function Contact() {
  return (
    <div>
      <section className="contact-section-header header-main">
        <h1
          style={{ marginTop: `-85px` }}
          className="header-main-heading text-center mb-1">
          Contact
        </h1>
        <h2 className="header-main-heading text-center mb-0">Christian Blue</h2>
      </section>
      <section className="contact-info">
        <div className="container">
          <h2 className="contact-info-head text-center">
            We’d love to hear from you!
          </h2>

          <p className="contact-info-subhead text-center">
            Please use the information below to contact us
          </p>

          <div className="row contact-row justify-content-center">
            <div className="col-md-4 col-lg-3 text-center mb-4">
              <i
                className="fa fa-phone rounded-circle icon d-flex align-items-center justify-content-center m-auto"
                aria-hidden="true"></i>
              <h3 className="contact-col-head mt-2">Phone</h3>
              <div className="contact-col-detail">
                Corporate Office <a href="tel:+18008602583">1-800-860-2583</a>
              </div>
            </div>
            <div className="col-md-4 col-lg-3 text-center mb-4">
              <i className="fa fa-envelope rounded-circle icon d-flex align-items-center justify-content-center m-auto"></i>
              <h3 className="contact-col-head mt-2">Email</h3>
              <div className="contact-col-detail">
                <a href="mailto:connect@christianblue.com">
                  connect@christianblue.com
                </a>
              </div>
            </div>
            <div className="col-md-4 col-lg-3 text-center">
              <i className="fa fa-map-marker rounded-circle icon d-flex align-items-center justify-content-center m-auto"></i>
              <h3 className="contact-col-head mt-2">Address</h3>
              <div className="contact-col-detail">
                <address>
                  Christian Blue Pages
                  <br/>521 Byers Rd Ste 102<br/>
                  Miamisburg, OH 45342
                </address>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
